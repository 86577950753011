import React from "react"
import Button from "../../components/Button"
import styled from "styled-components"
import Icon from "../../components/Icon"
import Typo from "../../components/Typo"
import { colors } from "../../Theme/colors"
import WineName from "../../components/WineName"
import { getWinePictureUrl } from "../../utils/winePicture"

const WinePictureContainer = styled.div`
  padding: 0 15px 20px 15px;
  border-bottom: 1px solid ${colors.gray.lighter};
  display: flex;
  flex-direction: column;
`

const WinePicture = styled.img`
  width: 100%;
  padding: 20px 0;
`

const StyledIcon = styled(Icon)`
  font-size: 20px;
  margin-right: 15px;
`

const WinePrincipalView = ({
  wine: { picture, name, isClientWine, metadata: { shelfName } = {} },
}) => {
  return (
    <WinePictureContainer>
      <WinePicture src={getWinePictureUrl(picture)} />
      {!(isClientWine === false) ? (
        shelfName && (
          <Button>
            <StyledIcon name="location" />
            <Typo title color="inherit">
              Votre vin se trouve en rayon <b>{shelfName}</b>
            </Typo>
          </Button>
        )
      ) : (
        <WineName name={name} />
      )}
    </WinePictureContainer>
  )
}

export default WinePrincipalView
