import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {createGlobalStyle} from "styled-components";


// import * as serviceWorker from './serviceWorker';
const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');
@import url('https://resources.matcha.wine/matcha-icon-font/style.css');
body{
margin: 0;
font-family: Open-sans, sans-serif;
font-size: 14px;
line-height: 17px;
} 

`
ReactDOM.render(
    <>
        <GlobalStyle/>
        <App/>
    </>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
