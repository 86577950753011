import React from "react"
import styled from "styled-components";
import Button from "../../components/Button"
import {getStyleColorFromWineColor} from "../../utils/wineColor"
import {colors} from "../../Theme/colors";

const Tag = styled(({colorId, ...rest}) => <Button {...rest}/>)`
  margin: 5px 5px 0 0;
  padding: 5px 10px;
  background-color: ${({colorId}) => getStyleColorFromWineColor(colorId)};
  color: ${({colorId}) => getStyleColorFromWineColor(colorId) && colorId !== "R" && colors.text.main};
  font-weight: 600;
`

const TagContainer = styled.div`
display: flex;
align-items: flex-start;
flex-wrap: wrap;
`

const Tags = ({tags}) => {
    return (
        <TagContainer>
            {tags.map(({name, id}, key) => <Tag key={key} colorId={id}>{name}</Tag>)}
        </TagContainer>
    )
}

export default Tags