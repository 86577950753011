import React from "react"
import WineBlock from "../WineBlock"
import styled from "styled-components"
import Typo from "../../components/Typo"
import {colors} from "../../Theme/colors"

const MapContainer = styled(WineBlock)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const ServiceContainer = styled(WineBlock)`
  padding: 0;
`

const StyledMap = styled.img`
  width: 50%;
`

const ZoneService = ({serviceTemperature, map}) => {
    return (
        <MapContainer>

            <ServiceContainer title="Service">
                <Typo color={colors.text.main}>
                    À servir à {serviceTemperature}
                </Typo>
            </ServiceContainer>

            <StyledMap src={map}/>

        </MapContainer>
    )
}

export default ZoneService