export const colors = {
    primary: "#DB414C",
    text: {
        main: "rgba(40,47,54,0.80)",
        price: "#271E17",
        wineName: "#282F36"
    },
    white: "#FFFFFF",
    gray: {
        lighter: "#D8D8D8",
        light: "#DCDCDC",
        medium: "#9B9B9B"
    }
}