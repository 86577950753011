import React from "react"
import styled from "styled-components"
import {colors} from "../../Theme/colors";
import BlockTitle from "../../components/BlockTitle"


const BlockContainer = styled.div`
  padding: 20px 15px 10px 15px;
`

const WineBlock = ({title, children, ...rest}) => {
    return (
        <BlockContainer {...rest}>
            {title && (
                <BlockTitle color={colors.primary} weight={500}>
                    {title}
                </BlockTitle>
            )}
            {children}
        </BlockContainer>
    )
}

export default WineBlock