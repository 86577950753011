import React from "react"
import styled from "styled-components"
import Typo from "../Typo"
import {colors} from "../../Theme/colors";

const StyledTypo = styled(Typo)`
  letter-spacing: 1.17px;
  text-transform: uppercase;
  margin-bottom: 10px;
`

const BlockTitle = ({children, ...rest}) => {
    return <StyledTypo color={colors.primary} weight={500} {...rest}>
        {children}
    </StyledTypo>
}

export default BlockTitle