import React from "react"
import WineBlock from "../WineBlock"
import Typo from "../../components/Typo"
import {colors} from "../../Theme/colors"

const Assets = ({assets}) => {
    return (
        <WineBlock title="Les +">
            {assets.map(({text}, key) => (<Typo key={key} color={colors.text.main}>{text}</Typo>))}
        </WineBlock>
    )
}

export default Assets