import React from "react"
import {colors} from "../../Theme/colors";
import Typo from "../Typo";


const WineName = ({name}) => {
    return (
        <Typo title color={colors.text.wineName}>
            {name}
        </Typo>
    )
}

export default WineName