import React from "react"
import Typo from "../../components/Typo"
import WineBlock from "../WineBlock"

const Anecdote = ({anecdote}) => {
    return (
        <WineBlock title="Pour la petite histoire">
            <Typo>
                {anecdote}
            </Typo>
        </WineBlock>
    )
}

export default Anecdote