import React from "react"
import styled from "styled-components"
import {colors} from "../../Theme/colors";

const StyledContainer = styled.div`
  background-color: ${colors.primary};
  padding: 10px 15px;
  color: ${colors.white};
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Button = ({children, ...rest}) => {
    return (
        <StyledContainer {...rest}>
            {children}
        </StyledContainer>
    )
}

export default Button

