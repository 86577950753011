import React from "react"
import Typo from "../../components/Typo"
import WineBlock from "../WineBlock"
import {colors} from "../../Theme/colors"

const PairingExamples = ({pairingExamples}) => {
    return (
        <WineBlock title="Idées d'accord">
            {pairingExamples.map(({dish: {name}}, key) => (<Typo color={colors.text.main} key={key}>{name}</Typo>))}
        </WineBlock>
    )
}

export default PairingExamples