import React from "react"
import styled from "styled-components"
import {colors} from "../../Theme/colors"

const StyledComponent = styled(({title = false, weight, color,...rest}) => <div {...rest}/>)`
  font-size: ${({title}) => title && "18px"};
  color: ${({color}) => color ? color : colors.text.main};
  font-weight: ${({weight}) =>  weight};
  line-height: ${({title}) => title && "20px"};
`


const Typo = ({children, ...props}) => {
    return (
        <StyledComponent {...props}>
            {children}
        </StyledComponent>
    )
}

export default Typo