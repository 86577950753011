import React, {useEffect} from 'react'
import {getQueryParams} from "./utils/queryParams"
import styled from "styled-components"
import _ from "lodash"
import {trackBuyAction} from "./utils/analytics"
import {
    WinePrincipalView,
    WineProfile,
    WineInfoView,
    Assets,
    Anecdote,
    PairingExamples,
    ZoneService
} from "./containers"


const AppContainer = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`

const App = () => {
    const {wine = {}, session} = getQueryParams()
    const {name, assets, anecdote, zone: {map} = {}, serviceTemperature, pairingExamples, viewTags = [], viewFeatures, isClientWine, isOrganic} = wine
    const {price, volume = 75} = _.get(wine, "products.0") || {}

    useEffect(() => {
        trackBuyAction(wine, session)
    }, [])

    return (
        <AppContainer className="ProductView-App">
            {_.isEmpty(wine)
                ? null
                : <>
                    <WinePrincipalView wine={wine}/>

                    {(! (isClientWine === false)) && <WineInfoView name={name} price={price} volume={volume} isOrganic={isOrganic}/>}

                    <Assets assets={assets}/>

                    <WineProfile tags={viewTags} features={viewFeatures} isClientWine={isClientWine}/>

                    <Anecdote anecdote={anecdote}/>

                    <ZoneService serviceTemperature={serviceTemperature} map={map}/>

                    <PairingExamples pairingExamples={pairingExamples}/>
                </>
            }
        </AppContainer>
    );
}

export default App;
