import React from "react"

const Icon = ({
  name,
  className = "",
  Tag = "span",
  prefix = "matcha-ycon",
}) => {
  const iconClassName = `${prefix} ${prefix}-${name} ${className}`

  return (
    <Tag className={iconClassName} aria-hidden="true">
      <span className="path1" />
      <span className="path2" />
      <span className="path3" />
    </Tag>
  )
}

export default Icon
