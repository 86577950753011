import React from "react"
import styled from "styled-components"
import Button from "../Button"
import Typo from "../Typo"
import {colors} from "../../Theme/colors"


const FullGauge = styled(Button)`
  width: 65%;
  background-color: ${colors.gray.light};
  height: 11px;
  padding: 0;
  justify-content: flex-start;
  margin-right: 15px;
`

const GaugeFill = styled(FullGauge)`
  margin: 0;
  height: 100%;
  background-color: ${colors.primary};
  width: ${({percent}) => percent}%;
`

const GaugeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`

const Gauge = ({percent, name}) => {
    return (
        <GaugeContainer>
            <FullGauge>
                <GaugeFill percent={percent}/>
            </FullGauge>
            <Typo color={colors.text.main}>
                {name}
            </Typo>
        </GaugeContainer>
    )
}

export default Gauge