import React from "react"
import WineBlock from "../WineBlock"
import Gauge from "../../components/Gauge"
import Tags from "../Tags"


const Gauges  = ({features}) => {
    return features.map(({name, value}, index) => {
        const percent = (100 / 5) * value

        return <Gauge key={index} percent={percent} name={name}/>
    })
}

const WineProfile = ({tags = [],  features, isClientWine}) => {
    return (
        <WineBlock title="Son profil">
            {(!(isClientWine === false)) && <Gauges features={features}/>}
            <Tags tags={tags}/>
        </WineBlock>
    )
}

export default WineProfile