import React from "react"
import styled from "styled-components"
import { colors } from "../../Theme/colors"
import Typo from "../../components/Typo"
import WineName from "../../components/WineName"
import Icon from "../../components/Icon"

const MarginIcon = styled(Icon)`
  margin-left: 15px;
  font-size: 25px;
  line-height: 25px;
`

const WineInfoContainer = styled.div`
  position: relative;
  border-bottom: 1px solid ${colors.gray.lighter};
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
`

const MarginTypo = styled(Typo)`
  margin: 15px 0;
`

const AbsoluteDiv = styled.div`
  position: absolute;
  bottom: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 30px);
`

const StyledIcon = styled(Icon)`
  font-size: 20px;
`

const AbsoluteIcon = styled(StyledIcon)`
  position: absolute;
  top: 5px;
`

const FlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.primary};
  background-color: ${colors.white};
`

const WineInfoView = ({ name, price, volume, isOrganic }) => {
  return (
    <WineInfoContainer>
      <WineName name={name} />
      <MarginTypo weight={600} title color={colors.text.price}>
        {price} €{isOrganic && <MarginIcon name="bio" />}
      </MarginTypo>
      <Typo color={colors.gray.medium}>{volume}cl</Typo>
      <AbsoluteDiv>
        <FlexColumnDiv>
          <AbsoluteIcon name="down-chevron" />
          <StyledIcon name="down-chevron" />
        </FlexColumnDiv>
      </AbsoluteDiv>
    </WineInfoContainer>
  )
}

export default WineInfoView
