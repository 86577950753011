import _ from "lodash"


const getBuyActionUrl = (wine, session) => {
    const {container = "", price = ""} = _.get(wine, "products.0") || {}
    const {buyUrl, url: wineUrl} = wine
    const url = buyUrl || wineUrl
    const {id, beginDate} = session
    return url && `${url}&buyAction=true&product=${container}&price=${price}&sessId=${id}&sessDate=${beginDate}`
}

const getNoRedirectBuyActionUrl = (wine, session) => {
    const buyActionUrl = getBuyActionUrl(wine, session)
    return `${buyActionUrl}&noRedirect=true`
}

export const trackBuyAction = (wine, session) => {
    if (!_.isEmpty(session) && !_.isEmpty(wine)) {
        console.log("Buy wine", wine)
        const url = getNoRedirectBuyActionUrl(wine, session)

        fetch(url)
            .then(response => {
                const ok = {response}

                if (!ok) console.warn("Wrong response when fetching Matcha redirection", response);
                // console.log(response)
            })
            .catch(e => {
                console.warn("Error when fetching Matcha redirection", e)
            })
    }
}
